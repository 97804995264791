export const escapeRegExp = str => str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&') //eslint-disable-line no-useless-escape

export const innerText = str => str.replace(/\s*<[^>]*>\s*/gm, '')

export const getFieldType = field => {
  return field.type.split('.')[1]
}

const isFieldType = (fieldType: string) => field => field.type === `$w.${fieldType}`

export const isUploadButton = isFieldType('UploadButton')
export const isRadioGroup = isFieldType('RadioButtonGroup')
export const isCheckbox = isFieldType('Checkbox')
export const isNumber = field => isFieldType('TextInput')(field) && field.inputType === 'number'
export const isRatingsInput = isFieldType('RatingsInput')

export const toMiliseconds = sec => sec * 1000
