import { ROLE_SUBMIT_BUTTON } from '../constants/roles'
import { isCheckbox, isNumber, isRatingsInput, isUploadButton } from './viewer-utils'
import * as _ from 'lodash'
import { SUBMISSION_DISPLAY_FIELD } from '../constants/wixcode'
import { getInputValue } from './input-value'
import * as Raven from 'raven-js'

export const ERROR_TYPE_TAG_KEY = 'reason'

const withCatch = (func: Function, type: string) => {
  try {
    Raven.captureBreadcrumb({ message: type, category: 'submit-flow' })
    return func()
  } catch (err) {
    Raven.setTagsContext({ [ERROR_TYPE_TAG_KEY]: type })
    throw err
  }
}

const withCatchAsync = async (func: Function, type: string) => {
  try {
    Raven.captureBreadcrumb({ message: type, category: 'submit-flow' })
    return await func()
  } catch (err) {
    Raven.setTagsContext({ [ERROR_TYPE_TAG_KEY]: type })
    throw err
  }
}

export const DEFAULT_SUBMIT_ERROR = 'unknown client error'

export const SUBMIT_ERROR_TYPES = {
  GET_ATTACHMENTS: 'get attachments',
  VALIDATE_FIELDS: 'validate fields',
  GET_FIELDS: 'get fields',
  GET_COLLECTION_FIELDS: 'get collection fields',
  SEND_TO_SERVER: 'send to server',
  SEND_TO_WIX_DATA: 'send to wix data',
  GET_SUBMIT_BUTTON: 'get submit button',
  RESET_FIELDS: 'reset fields',
  SEND_LEAD: 'send lead',
}

export const getSubmitButton = ($w, throwException = true) =>
  withCatch(() => {
    const $submitButton = $w(`@${ROLE_SUBMIT_BUTTON}`)[0]
    if ($submitButton) {
      return $submitButton
    }

    if (throwException) {
      throw new Error('cannot find submit button')
    } else {
      return null
    }
  }, SUBMIT_ERROR_TYPES.GET_SUBMIT_BUTTON)

export const getFields = ({ $w, roles }) =>
  withCatch(() => {
    const fields = roles.reduce((res, roleField) => res.concat($w(`@${roleField}`)), [])
    return _.uniqBy(fields, (field: { uniqueId: string }) => field.uniqueId)
  }, SUBMIT_ERROR_TYPES.GET_FIELDS)

export const validateFields = ({ fields, strategy }) =>
  withCatch(() => {
    fields.forEach(field => field.updateValidityIndication && field.updateValidityIndication())
    return strategy.validateFields(fields)
  }, SUBMIT_ERROR_TYPES.VALIDATE_FIELDS)

export const getAttachments = fields =>
  withCatchAsync(async () => {
    const uploadButtons = fields.filter(field => isUploadButton(field) && field.value.length > 0)
    return Promise.all(
      uploadButtons.map(async uploadButtonField => {
        const { url } = await uploadButtonField.startUpload()
        return {
          url,
          name: uploadButtonField.value[0].name,
        }
      })
    )
  }, SUBMIT_ERROR_TYPES.GET_ATTACHMENTS)

export const sendFieldsToServer = async ({ strategy, attachments, fields }) =>
  withCatchAsync(async () => {
    return await strategy.execute({ attachments, fields })
  }, SUBMIT_ERROR_TYPES.SEND_TO_SERVER)

export const getCollectionFields = ({ fields, attachments }) =>
  withCatch(() => {
    const toInsert = {
      [SUBMISSION_DISPLAY_FIELD]: new Date(),
    }

    fields.forEach(field => {
      if (!_.get(field, 'connectionConfig.collectionFieldKey')) {
        return
      }

      let value = getInputValue(field, attachments)
      if (isUploadButton(field)) {
        value = _.get(value, 'url')
      }

      if (isCheckbox(field)) {
        value = value ? 'V' : ''
      }

      if (isNumber(field) || isRatingsInput(field)) {
        value = Number(value)
      }

      toInsert[field.connectionConfig.collectionFieldKey] = value
    })

    return toInsert
  }, SUBMIT_ERROR_TYPES.GET_COLLECTION_FIELDS)

const isCollectionIdExists = async ({wixData, collectionId}) => {
  if (_.isEmpty(collectionId)) {
    return false
  }

  try {
    const schemas: string[] = (await wixData.getSchema()).schemas.map(s => s.id)
    return schemas.includes(collectionId)
  } catch {
    return false
  }
}

export const sendFieldsToWixData = async ({ wixData, collectionId, fieldsToInsert }) =>
  withCatchAsync(async () => {
    const isValidCollectionId = await isCollectionIdExists({ wixData, collectionId })
    if (!isValidCollectionId) {
      return Promise.resolve()
    }

    await wixData.insert(collectionId, fieldsToInsert)
  }, SUBMIT_ERROR_TYPES.SEND_TO_WIX_DATA)

const getCurrentPageName = ({ wixSite, wixLocation }) => {
  const siteStructure = wixSite.getSiteStructure()

  const currentPath = wixLocation.path

  let currentPageName

  if (currentPath.length > 0) {
    const url = _(currentPath).last()
    const currentPageStructure = _.find(siteStructure.pages, ['url', `/${url}`])
    currentPageName = _.get(currentPageStructure, 'name')
  } else {
    const homePageStructure = _.find(siteStructure.pages, ['isHomePage', true])
    currentPageName = _.get(homePageStructure, 'name')
  }

  return currentPageName
}

export const sendWixAnalytics = ({ wixSite, wixLocation, wixWindow }) => {
  withCatch(() => {
    const currentPageName = getCurrentPageName({ wixSite, wixLocation })

    if (!currentPageName)
      return

    wixWindow.trackEvent('Lead', {
      label: `Page Name: ${currentPageName}`,
    })
  }, SUBMIT_ERROR_TYPES.SEND_LEAD)
}

export const resetFields = fields =>
  withCatch(() => {
    fields.forEach(field => {
      if (isUploadButton(field)) {
        field.reset()
        return
      }

      if (isCheckbox(field)) {
        field.checked = false
      } else {
        field.value = null
      }

      if ('resetValidityIndication' in field) {
        field.resetValidityIndication()
      }
    })
  }, SUBMIT_ERROR_TYPES.RESET_FIELDS)
