export const ROLE_FORM = 'form_Role'
export const ROLE_SUBMIT_BUTTON = 'button_Role'
export const ROLE_MESSAGE = 'message_Role'
export const ROLE_TITLE = 'title_Role'
export const INPUT_FIELDS_PREFIX = 'field_role_'
export const CONNECTED_ELEMENT_PREFIX = 'connectedElement_role_'

export const ROLE_FIELD_REGISTRATION_FORM_LINK_TO_LOGIN_DIALOG = `${CONNECTED_ELEMENT_PREFIX}regForm_linkToLoginDialog`
export const ROLE_FIELD_REGISTRATION_FORM_CODE_OF_CONDUCT = `${CONNECTED_ELEMENT_PREFIX}regForm_emptyLinkCoc`
export const ROLE_FIELD_REGISTRATION_FORM_PRIVACY_POLICY = `${CONNECTED_ELEMENT_PREFIX}regForm_emptyLinkPrivacy`
export const ROLE_FIELD_REGISTRATION_FORM_TERMS_OF_USE = `${CONNECTED_ELEMENT_PREFIX}regForm_emptyLinkTou`

export const FIELDS = {
  ROLE_FIELD_TEXT: `${INPUT_FIELDS_PREFIX}text`,
  ROLE_FIELD_RATING: `${INPUT_FIELDS_PREFIX}rating`,
  ROLE_FIELD_TEXT_AREA: `${INPUT_FIELDS_PREFIX}text_area`,
  ROLE_FIELD_DATE: `${INPUT_FIELDS_PREFIX}date`,
  ROLE_FIELD_SELECT: `${INPUT_FIELDS_PREFIX}select`,
  ROLE_FIELD_RADIO_LIST: `${INPUT_FIELDS_PREFIX}radio_list`,
  ROLE_FIELD_FILE_UPLOAD: `${INPUT_FIELDS_PREFIX}file_upload`,
  ROLE_FIELD_CHECKBOX: `${INPUT_FIELDS_PREFIX}checkbox`,
  ROLE_FIELD_SUBSCRIBE: `${INPUT_FIELDS_PREFIX}subscribe`,
  ROLE_FIELD_CHECKBOX_GROUP: `${INPUT_FIELDS_PREFIX}checkbox_group`,
  ROLE_FIELD_REGISTRATION_FORM_LOGIN_EMAIL: `${INPUT_FIELDS_PREFIX}regForm_loginEmail`,
  ROLE_FIELD_REGISTRATION_FORM_PASSWORD: `${INPUT_FIELDS_PREFIX}regForm_password`,
  ROLE_FIELD_REGISTRATION_FORM_CHECKBOX_AGREE_TERMS: `${INPUT_FIELDS_PREFIX}regForm_checkboxAgreeTerms`,
  ROLE_FIELD_REGISTRATION_FORM_CHECKBOX_JOIN_COMMUNITY: `${INPUT_FIELDS_PREFIX}regForm_checkboxJoinCommunity`,
}
export const FIELDS_ROLES = Object.keys(FIELDS).map(fieldKey => FIELDS[fieldKey])

const hasDuplicates = arr => new Set(arr).size !== arr.length
if (hasDuplicates(FIELDS_ROLES)) {
  console.warn('FIELDS_ROLES cannot have duplicates!')
}
