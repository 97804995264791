import { FormStrategy } from './form-strategy'
import { RegistrationFormStrategy } from './registraion-form-strategy'

interface FormStrategyConstructor {
  new ($w, initInstance, linksUtil): FormStrategy

  isEnabled($w): boolean
}

export const strategies: FormStrategyConstructor[] = [RegistrationFormStrategy, FormStrategy]
